<template>
  <div class="store-home">
    <flip-card :data="random"></flip-card>
    <search-bar></search-bar>
    <scroll :top="scrollTop" @onScroll="onScroll" ref="scroll">
      <div class="banner-wrapper">
        <!-- <div class="banner-img" :style="{backgroundImage: `url('${banner}')`}"></div> -->
        <swiper class="banner-img" :proImages="banner"></swiper>
      </div>
      <guess-you-like :data="guessYouLike"></guess-you-like>
      <recommend :data="recommend" class="recmomend"></recommend>
      <featured :data="featured" :titleText="$t('home.featured')" :btnText="$t('home.seeAll')" class="featured"></featured>
      <div class="category-list-wrapper" v-for="(item, index) in categoryList" :key="index">
        <category-book :data="item"></category-book>
      </div>
      <category class="categories" :data="categories"></category>
    </scroll>
  </div>
</template>

<script>
import SearchBar from '@/components/home/SearchBar'
import Scroll from '@/components/common/Scroll'
import Swiper from '@/components/common/Swiper'
import FlipCard from '@/components/home/FlipCard'
import GuessYouLike from '@/components/home/GuessYouLike.vue'
import Recommend from '@/components/home/Recommend.vue'
import Featured from '@/components/home/Featured.vue'
import CategoryBook from '@/components/home/CategoryBook.vue'
import Category from '@/components/home/Category.vue'

import { StoreHomeMixin } from '@/utils/mixin'
import { getHome } from '@/api/store'
export default {
  name: 'storeHome',
  data() {
    return {
      scrollTop: 94,
      random: null,
      banner: [],
      guessYouLike: null, // 猜你喜欢
      recommend: null, // 推荐
      featured: null, // 精选
      categoryList: null,
      categories: null // 分类
    }
  },
  mixins: [StoreHomeMixin],
  components: {
    SearchBar,
    Scroll,
    Swiper,
    FlipCard,
    GuessYouLike,
    Recommend,
    Featured,
    CategoryBook,
    Category
  },
  mounted() {
    this.getHomeData()
  },
  methods: {
    onScroll(offsetY) {
      this.setOffsetY(offsetY)
      if (offsetY > 0) {
        this.scrollTop = 52
      } else {
        this.scrollTop = 94
      }
      this.$refs.scroll.refresh() // 重新计算scroll高度
    },
    getHomeData() {
      getHome().then(res => {
        const data = res
        const randomIndex = Math.floor(Math.random() * res.random.length)
        this.random = data.random[randomIndex]
        this.banner = data.banner
        this.guessYouLike = data.guessYouLike
        this.recommend = data.recommend
        this.featured = data.featured
        this.categoryList = data.categoryList
        this.categories = data.categories
      })
    }
  }
}
</script>

<style lang='scss' scoped>
@import '../../assets/styles/global';
.store-home {
  width: 100%;
  height: 100%;
  .banner-wrapper {
    width: 100%;
    padding: px2rem(10);
    box-sizing: border-box;
    .banner-img {
      width: 100%;
      height: px2rem(150);
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
  .recmomend {
    margin-top: px2rem(20);
  }
  .featured {
    margin-top: px2rem(20);
  }
  .category-list-wrapper {
    margin-top: px2rem(20);
  }
  .categories {
    margin-top: px2rem(20);
  }
}
</style>
