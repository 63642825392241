<template>
  <div class="flip-card-wrapper" v-if="flipCardVisible">
    <div class="flip-card-bg">
      <ebook-loading v-show="!ifShowBookCard"></ebook-loading>
    </div>
        <div class="book-card" :class="{'animation': runBookCardAnimation}" v-show="ifShowBookCard">
      <div class="book-card-wrapper">
        <div class="img-wrapper">
          <img class="img" v-lazy="data.cover">
        </div>
        <div class="content-wrapper">
          <div class="title">{{data.title || data.fileName}}</div>
          <div class="author sub-title-medium">{{data.author}}</div>
          <div class="category">{{categoryText()}}</div>
        </div>
        <div class="read-btn" @click.stop="showBookDetail">{{$t('home.readNow')}}</div>
      </div>
    </div>
    <div class="close-btn-wrapper" @click="close">
      <div class="icon-close"></div>
    </div>
  </div>
</template>

<script>
import EbookLoading from '@/components/ebook/EbookLoading'
import { StoreHomeMixin } from '@/utils/mixin'
import { categoryText, showBookDetail } from '@/utils/store'

export default {
  name: 'FlipCard',
  components: {
    EbookLoading
  },
  data() {
    return {
      runBookCardAnimation: false
    }
  },
  props: {
    data: Object
  },
  mixins: [StoreHomeMixin],
  methods: {
    showBookDetail() {
      if (this.data) {
        console.log(this.data)
        showBookDetail(this, this.data)
        this.setFlipCardVisible(false)
        this.setIfShowBookCard(false)
      }
    },
    categoryText() {
      if (this.data) {
        return categoryText(this.data.category, this)
      }
    },
    close() {
      this.setFlipCardVisible(false)
      this.setIfShowBookCard(false)
    }
  }
}
</script>

<style lang='scss' scoped>
@import '../../assets/styles/global';
$color-blue: rgb(74, 171, 255);
.flip-card-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: .6);
  @include center;
  .flip-card-bg {
    width: 100%;
    height: 100%;
    @include center;
  }
  .book-card {
    position: absolute;
    width: 65%;
    // height: 70%;
    box-sizing: border-box;
    border-radius: px2rem(15);
    background: white;
    &.animation {
      animation: scale .3s ease-in both;
      @keyframes scale {
        0% {
          transform: scale(0);
          opacity: 0;
        }
        100% {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
    .book-card-wrapper {
      width: 100%;
      height: 100%;
      margin-bottom: px2rem(30);
      @include columnTop;
      .img-wrapper {
        width: 100%;
        margin-top: px2rem(20);
        @include center;
        .img {
          width: px2rem(90);
          height: px2rem(130);
        }
      }
      .content-wrapper {
        padding: 0 px2rem(20);
        margin-top: px2rem(20);
        .title {
          color: #333;
          font-weight: bold;
          font-size: px2rem(18);
          line-height: px2rem(20);
          max-height: px2rem(40);
          text-align: center;
          @include ellipsis2(2)
        }
        .author {
          margin-top: px2rem(10);
          text-align: center;
        }
        .category {
          color: #999;
          font-size: px2rem(14);
          margin-top: px2rem(10);
          margin-bottom: px2rem(20);
          text-align: center;
        }
      }
      .read-btn {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1100;
        width: 100%;
        border-radius: 0 0 px2rem(15) px2rem(15);
        padding: px2rem(15) 0;
        text-align: center;
        color: white;
        font-size: px2rem(14);
        background: $color-blue;
      }
    }
   }
  .close-btn-wrapper {
    position: absolute;
    left: 0;
    bottom: px2rem(30);
    z-index: 1100;
    width: 100%;
    @include center;
    .icon-close {
      width: px2rem(45);
      height: px2rem(45);
      border-radius: 50%;
      background: #333;
      font-size: px2rem(25);
      color: white;
      @include center;
    }
  }
}
</style>
