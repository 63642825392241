<template>
  <swiper
    class="swiper"
    ref="swiper"
    :options="swiperOption"
    v-if="proImages"
    @mouseenter.native="on_top_enter"
    @mouseleave.native="on_top_leave"
  >
    <swiper-slide v-for="item in proImages" :key="item">
      <img :src="item" alt="" class="banner-img">
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'swiper-example-change-direction',
  title: 'Change direction',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    proImages: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      swiperOption: {
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        },
        // slidesPerView: "auto",
        loop: true, // 开启循环模式
        spaceBetween: 10,
        on: {
          resize: () => {
            this.$refs.swiper.$swiper.changeDirection(
              window.innerWidth <= 960 ? 'vertical' : 'horizontal'
            )
          }
        }
      }
    }
  },
  methods: {
    on_top_enter() {
      this.$refs.swiper.$swiper.autoplay.stop()
    },
    on_top_leave() {
      this.$refs.swiper.$swiper.autoplay.start()
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-img {
  width: 100%;
  height: 100%;
}
</style>
